<template>
  <div class='text-center py-16 px-8 max-w-xl mx-auto overflow-y-auto'>
    <check-circle-icon class='w-full mb-4 text-blue-600' size='6x' />
    <div class='font-semibold text-2xl w-full'>등록이 완료되었습니다.</div>
    <div class='mt-2 text-gray-500 mb-12'>등록 및 결제 내용은 등록완료 메일을 확인해 주시기 바랍니다.</div>
    <div class='bg-gray-100 p-8 mb-12'>
      <div class='flex flex-row'>
        <div class='font-semibold w-1/3 text-left'>주문번호</div>
        <div class='text-gray-500 w-2/3 text-right'>{{hashedOrderId}}</div>
      </div>
      <table class='w-full text-sm table-fixed mt-8'>
        <thead>
          <tr class='uppercase border-b'>
            <th class='text-left'>Attendee</th>
            <th class='text-right'>Registration Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(attendee, index) in registeringAttendees'
            :key='`payment-finished-registering-attendee-${index}`'
            class='border-b cursor-pointer'
            @click='openRegistration(attendee, index)'>
            <td class='py-2 text-left'>
              {{attendee.profile.firstName}} {{attendee.profile.lastName}}<br>
              {{attendee.email}}
            </td>
            <td class='py-2 text-right'>{{attendeePaymentString(attendee)}} ({{attendee.registrationProduct.name}} )</td>
          </tr>
        </tbody>
      </table>
      <div class='mt-8'>
        <div class='bg-gray-100 text-gray-900 uppercase font-semibold flex flex-row justify-between items-center'>
          <span>Total Amount Paid (KRW):</span>
          <span>{{totalAmountDueString}}</span>
        </div>
      </div>
    </div>
    <button
      @click='redirectAfterAction'
      class='border py-4 px-8 text-center text-white text-sm uppercase w-full bg-gray-900 block rounded-md'>
      홈으로 가기
    </button>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@vue-hero-icons/outline'
import numbro from 'numbro'
import isEmpty from 'lodash/isEmpty'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'RegistrationFinishedBatch',
  props: [
    'isModalPayment',
    'paymentParamsProp',
  ],
  data () {
    return {
      closeBrowserTabTimer: null,
      waitingTimeBeforeClose: 30
    }
  },
  components: {
    CheckCircleIcon
  },
  watch: {
    'waitingTimeBeforeClose': {
      handler: function (newVal, oldVal) {
        if (!newVal && newVal !== oldVal) {
          window.close()
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('payments', [
      'currentOrder'
    ]),
    ...mapState('users', [
      'registeringAttendees',
    ]),
    ...mapGetters ([
      'isMobile'
    ]),
    hashedOrderId () {
      if (this.isModalPayment) {
        return this.paymentParamsProp.orderId
      } else {
        return this.$route.query.orderId
      }
    },
    totalAmountDue () {
      return this.registeringAttendees.map(attendee => {
        return (attendee.registrationProduct && attendee.registrationProduct.price.amount) ? attendee.registrationProduct.price.amount : 0
      }).reduce((a, b) => a + b, 0)
    },
    totalAmountDueString () {
      return numbro(this.totalAmountDue).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
  },
  methods: {
    ...mapMutations('users', [
      'resetRegisteringAttendees',
    ]),
    ...mapActions('payments', [
      'resetCurrentOrder',
    ]),
    closeBrowserTab () {
      window.close()
    },
    attendeePaymentString (attendee) {
      return (attendee.registrationProduct && !isEmpty(attendee.registrationProduct.price)) ? `${attendee.registrationProduct.price.priceUnit} ${attendee.registrationProduct.price.amount}` : ''
    },
    redirectAfterAction () {
      this.resetRegisteringAttendees()
      let redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Main'
      this.$router.push({name: redirectRoute})
    },
  },
  mounted () {
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'empty-page')
    } 
    this.closeBrowserTabTimer = setInterval(() => {
      this.waitingTimeBeforeClose = this.waitingTimeBeforeClose - 1
    }, 1000)
  },
  beforeDestroy () {
    this.resetCurrentOrder()
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'default-layout')
    }
    clearInterval(this.closeBrowserTabTimer)
  },
}
</script>
