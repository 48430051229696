<template>
  <div class='text-center py-16 px-8 max-w-xl mx-auto'>
    <check-circle-icon class='w-full mb-4 text-blue-600' size='6x' />
    <div class='font-semibold text-2xl w-full'>결제가 완료되었습니다.</div>
    <div class='mt-2 text-gray-500 mb-12'>마이페이지에서 해당 구매내역을 확인하실 수 있습니다.</div>
    <div class='bg-gray-100 p-8 mb-12'>
      <div class='flex flex-row mb-4'>
        <div class='font-semibold w-1/3 text-left'>주문번호</div>
        <div class='text-gray-500 w-2/3 text-right'>{{hashedOrderId}}</div>
      </div>
      <div class='flex flex-row mb-4'>
        <div class='font-semibold w-1/3 text-left'>주문상품</div>
        <div class='text-gray-500 w-2/3 text-right'>{{currentOrder.orderName}}</div>
      </div>
      <div class='flex flex-row'>
        <div class='font-semibold w-1/3 text-left'>결제금액</div>
        <div class='text-gray-500 w-2/3 text-right'>{{orderAmountString}}원</div>
      </div>
    </div>
    <slot>
      <div v-if='!isMobile'>
        <button
          @click='closeBrowserTab'
          class='py-4 font-semibold bg-blue-500 text-center text-white w-full rounded-md'>위 내용을 확인하였습니다</button>
        <div class='mt-2 text-sm text-gray-500'>{{waitingTimeBeforeClose}}후에 자동으로 창이 닫힙니다.</div>
      </div>
    </slot>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@vue-hero-icons/outline'
import numbro from 'numbro'
import { mapState, mapGetters, mapActions } from 'vuex'


export default {
  name: 'PaymentFinished',
  props: [
    'isModalPayment',
  ],
  data () {
    return {
      closeBrowserTabTimer: null,
      waitingTimeBeforeClose: 30
    }
  },
  components: {
    CheckCircleIcon
  },
  watch: {
    'waitingTimeBeforeClose': {
      handler: function (newVal, oldVal) {
        if (!newVal && newVal !== oldVal) {
          window.close()
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('payments', [
      'currentOrder'
    ]),
    ...mapGetters ([
      'isMobile'
    ]),
    hashedOrderId () {
      return (this.$route.query.orderId) ? this.$route.query.orderId : this.currentOrder.hashedOrderId
    },
    orderAmountString () {
      let amount = this.currentOrder.price ? this.currentOrder.price.amount : 0
      return numbro(amount).format({thousandSeparated: true})
    }
  },
  methods: {
    ...mapActions('payments', [
      'resetCurrentOrder',
    ]),
    closeBrowserTab () {
      window.close()
    }
  },
  mounted () {
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'empty-page')
    } 
    this.closeBrowserTabTimer = setInterval(() => {
      this.waitingTimeBeforeClose = this.waitingTimeBeforeClose - 1
    }, 1000)
  },
  beforeDestroy () {
    this.resetCurrentOrder()
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'default-layout')
    }
    clearInterval(this.closeBrowserTabTimer)
  },
}
</script>
