<template>
  <div class='text-center py-32 px-8 max-w-lg mx-auto'>
    <i class='el-icon-loading text-6xl mb-4'></i>
    <div class='font-semibold text-2xl mb-24'>{{paymentSuccessTitle}}</div>
    <div>{{paymentSuccessDescription}}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'PaymentSuccess',
  props: {
    paymentParamsProp: {
      type: Object
    },
    isModalPayment: {
      default: false,
      type: Boolean
    },
    isKorean: {
      default: true,
      type: Boolean
    },
  },
  data () {
    return {
      paymentSuccessTracker: null,
      waitingTimeForPaymentSuccess: 0
    }
  },
  watch: {
    'isCurrentOrderDone': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          if (this.hasCurrentCoupon) {
            this.updateUserRegistrationFeeDiscountCouponData()
            this.useCouponByCode()
          }
          if (this.isModalPayment) {
            this.$emit('payment-finished')
          } else {
             this.$router.push({name: 'PaymentFinished', query: {orderId: this.$route.query.orderId}})
          }
        }
      },
      immediate: true
    },
    'waitingTimeForPaymentSuccess': {
      handler: function (newVal) {
        if (newVal >= 2000) {
          clearInterval(this.paymentSuccessTracker)
          this.$alert(this.paymentFailMessage, {
            type: 'warning'
          }).then(() => {
            if (this.isModalPayment) {
              this.$emit('payment-fail', this.paymentParams.orderId)
            } else {
              this.$router.push({name: 'PaymentFail', query: {orderId: this.paymentParams.orderId}})
            }
          })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('registrationFeeDiscountCoupons', [
      'currentCoupon'
    ]),
    ...mapGetters('payments', [
      'isCurrentOrderDone',
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
    ...mapGetters('registrationFeeDiscountCoupons', [
      'hasCurrentCoupon',
    ]),
    paymentParams () {
      if (this.isModalPayment) {
        return this.paymentParamsProp
      } else {
        return { 
          pg:         this.$route.query.pg,
          orderId:    this.$route.query.orderId,
          paymentKey: this.$route.query.paymentKey,
          impUid:     this.$route.query.impUid,
          encData:    this.$route.query.encData,
          encInfo:    this.$route.query.encInfo,
          tranCd:     this.$route.query.tranCd,
        }
      }
    },
    tossPaymentOrderParams () {
      return {
        event_id:    this.showingEventId,
        order_id:    this.$route.query.orderId,
        payment_key: this.$route.query.paymentKey,
        amount:      this.$route.query.amount,
      }
    },
    iamportPaymentOrderParams () {
      return {
        event_id:     this.showingEventId,
        merchant_uid: this.paymentParams.orderId,
        payment_key:  this.paymentParams.paymentKey,
        imp_uid:      this.paymentParams.impUid,
        single_order: true,
      }
    },
    nhnkcpPaymentOrderParams () {
      return {
        event_id:  this.showingEventId,
        ordr_idxx: this.paymentParams.orderId,
        enc_data:  this.paymentParams.encData,
        enc_info:  this.paymentParams.encInfo,
        tran_cd:   this.paymentParams.tranCd,
        single_order: true,
      }
    },
    paymentFailMessage () {
      return this.isKorean ? '결제가 정상적으로 처리되지 않았습니다.' : 'Your payment could not be processed.'
    },
    paymentSuccessTitle () {
      return this.isKorean ? '결제가 진행중입니다.' : 'Your payment is currently being processed.'
    },
    paymentSuccessDescription () {
      return this.isKorean ? '잠시만 기다려주세요. 결제 진행중에 앱을 닫으시거나 다른페이지로 이동하시면, 오류가 발생할 수 있습니다.' : 'Please do not refresh or navigate to another page. Doing so can cause the payment to fail.'
    },
  },
  methods: {
    ...mapActions('payments', [
      'checkPaymentStatus',
    ]),
    ...mapActions('tossPayments', [
      'requestTossPaymentOnSuccess',
    ]),
    ...mapActions('iamportPayments', [
      'requestIamportPaymentOnSuccess',
    ]),
    ...mapActions('nhnkcpPayments', [
      'requestNhnKcpPaymentOnSuccess',
    ]),
    ...mapActions('registrationFeeDiscountCoupons', [
      'useCouponByCode',
    ]),
    ...mapActions('users', [
      'patchRegistrationData',
    ]),
    updateUserRegistrationFeeDiscountCouponData () {
      this.patchRegistrationData({ registrationFeeDiscountCouponCode: this.currentCoupon.code })
    },
  },
  mounted () {
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'empty-page')
    } 
    if (this.paymentParams.pg.includes('iamport')) {
      // iamport payment
      this.requestIamportPaymentOnSuccess(this.iamportPaymentOrderParams)
      this.paymentSuccessTracker = setInterval(() => {
        this.waitingTimeForPaymentSuccess += 500
        this.checkPaymentStatus(this.paymentParams.orderId)
      }, 5000)
    } else if (this.paymentParams.pg.includes('nhnkcp')) {
      this.requestNhnKcpPaymentOnSuccess(this.nhnkcpPaymentOrderParams)
      this.paymentSuccessTracker = setInterval(() => {
        this.waitingTimeForPaymentSuccess += 500
        this.checkPaymentStatus(this.paymentParams.orderId)
      }, 5000)
    } else if (this.$route.query.pg && this.$route.query.pg === 'toss') {
      // toss payment
      this.requestTossPaymentOnSuccess(this.tossPaymentOrderParams)
      this.paymentSuccessTracker = setInterval(() => {
        this.waitingTimeForPaymentSuccess += 500
        this.checkPaymentStatus(this.$route.query.orderId)
      }, 5000)
    }
  },
  beforeDestroy () {
     if (!this.isModalPayment) {
      this.$emit('update:layout', 'default-layout')
    }
    clearInterval(this.paymentSuccessTracker)
  },
}
</script>
