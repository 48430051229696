<template>
  <div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'IamportPayment',
  props: [
    'paymentParamsProp',
    'isModalPayment',
    'isSingleOrder',
    'isKorean',
  ],
  computed: {
    ...mapGetters('events', [
      'showingEventRegistrationConfigurations',
    ]),
    iamportRequestPayName () {
      return this.showingEventRegistrationConfigurations ? this.showingEventRegistrationConfigurations.iamportRequestPayName : 'Registration Fee'
    },
    hasPaymentData () {
      if (this.isModalPayment) {
        return this.hasPaymentParamsProp
      } else {
        return this.hasQueryParams
      }
    },
    hasPaymentParamsProp () {
      return this.paymentParamsProp && 
             this.paymentParamsProp.pg &&
             this.paymentParamsProp.currency &&
             this.paymentParamsProp.amount &&
             this.paymentParamsProp.orderId &&
             this.paymentParamsProp.orderName &&
             this.paymentParamsProp.customerName
    },
    hasQueryParams () {
      return this.$route.query.pg &&
             this.$route.query.currency &&
             this.$route.query.amount &&
             this.$route.query.orderId &&
             this.$route.query.orderName &&
             this.$route.query.customerName
    },
    paymentParams () {
      if (this.isModalPayment) {
        return this.paymentParamsProp
      } else {
        return {
          pg:           this.$route.query.pg,
          currency:     this.$route.query.currency,
          amount:       this.$route.query.amount,
          orderId:      this.$route.query.orderId,
          orderName:    this.$route.query.orderName,
          customerName: this.$route.query.customerName,
        }
      }
    },
    paymentFailMessage () {
      return this.isKorean ? '결제가 정상적으로 처리되지 않았습니다.' : 'Your payment could not be processed.'
    },
  },
  methods: {
    initIamportPayment () {
      if (this.hasPaymentData) {
        let language = ''
        let pg = ''
        let noticeUrl = this.isSingleOrder ? `${process.env.VUE_APP_API}/api/v1/iamport_payments/success?single_order=true` : `${process.env.VUE_APP_API}/api/v1/iamport_payments/success`
        if (this.paymentParams.pg.includes('nice')) {
          // koa2022 nice
          language = 'ko'
          pg = `${this.paymentParams.pg.split('-')[1]}${process.env.VUE_APP_KOA_IAMPORT_PG_NICE_ID}`
        } else if (this.paymentParams.pg.includes('eximbay')) {
          // koa2022 eximbay
          language = 'en'
          pg = `${this.paymentParams.pg.split('-')[1]}${process.env.VUE_APP_KOA_IAMPORT_PG_EXIMBAY_ID}`
        } else {
          // kossoasc kg inicis
          language = 'ko'
          pg = `${this.paymentParams.pg.split('-')[1]}${process.env.VUE_APP_KOSSOASC_IAMPORT_PG_KG_INICIS_ID}`
        }
        var IMP = window.IMP
        IMP.init(process.env.VUE_APP_IAMPORT_MID)
        IMP.request_pay({
            pg:           pg,
            pay_method:   'card',
            currency:     this.paymentParams.currency,
            merchant_uid: this.paymentParams.orderId,
            notice_url:   noticeUrl,
            name:         this.iamportRequestPayName,
            amount:       this.paymentParams.amount,
            buyer_name:   this.paymentParams.customerName,
            buyer_tel:    this.paymentParams.customerPhoneNumber,
            language:     language,
            // Koa2022 registration mobile
            // imp_uid, merchant_uid, success 가 query string으로 전달됨.
            m_redirect_url: `${window.location.origin}/iamport-payment/registration-success-mobile?pg=${this.paymentParams.pg}&single_order=${this.isSingleOrder}&is_korean=${this.isKorean}&order_id=${this.paymentParams.orderId}`
        }, (resp) => {
          if (resp.success) {
            let successParams = {
              pg:         this.paymentParams.pg, 
              orderId:    this.paymentParams.orderId,
              impUid:     resp.imp_uid, 
              paymentKey: resp.pg_tid 
            }
            if (this.isModalPayment) {
              // Koa2022 registration pc
              this.$emit('payment-success', successParams)
            } else {
              // not using now
              this.$router.push({name: 'PaymentSuccess', query: successParams})
            }
          } else {
            console.error(resp)
            this.handlePaymentFail()
          }
        })
      } else {
        this.handlePaymentFail()
      }
    },
    handlePaymentFail () {
      this.$alert(this.paymentFailMessage, {
        type: 'warning'
      }).then(() => {
        if (this.isModalPayment) {
          this.$emit('payment-fail', this.paymentParams.orderId)
        } else {
          this.$router.push({name: 'PaymentFail', query: {orderId: this.paymentParams.orderId}})
        }
      })
    }
  },
  mounted () {
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'empty-page')
    } 
    this.initIamportPayment()
  },
  beforeDestroy () {
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'default-layout')
    }
  },
}
</script>
